define("@outdoorsyco/ember-shared-data/components/see-more/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8aJsaSSY",
    "block": "{\"symbols\":[\"@description\",\"@name\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"show-more-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[28,\"if\",[[28,\"not\",[[23,0,[\"isExpanded\"]]],null],\"collapsed\"],null]],[8],[0,\"\\n    \"],[14,3,[[23,2,[]],[23,1,[]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"shouldToggle\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",\"show-more\"],[12,\"type\",\"button\"],[3,\"on\",[\"click\",[23,0,[\"toggleExpand\"]]]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"template-toggle-see-less-see-less\"],[[\"value\"],[[28,\"if\",[[23,0,[\"isExpanded\"]],\"Less\",\"More\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/see-more/template.hbs"
    }
  });

  _exports.default = _default;
});