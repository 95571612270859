define("portal/controllers/bookings/show/beta", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    prompt: Ember.inject.service(),
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.availableBundles = [];
    },

    esignService: Ember.computed('model.premiumServicesWithoutLeadInsuranceFees', function () {
      return this.get('model.premiumServicesWithoutLeadInsuranceFees').findBy('category', 'esign');
    }),
    premiumServices: Ember.computed('model.premiumServicesWithoutLeadInsuranceFees', function () {
      const premiumServices = this.model.premiumServicesWithoutLeadInsuranceFees;
      return premiumServices.filter(ps => ps.category !== 'esign');
    }),
    showPayoutActualTransferMark: Ember.computed('model', function () {
      return this.get('model.payoutActualTransferAmount') !== undefined;
    }),
    nydusBooking: Ember.computed('model.id', function () {
      if (this.get('model.external')) {
        return this.store.findRecord('nydus-booking', this.get('model.id'));
      }

      return {};
    }),
    backingBundle: Ember.computed('model.bundleId', function () {
      let bundleId = this.get('model.bundleId');
      return this.store.findRecord('bundle', bundleId);
    }),
    currentBundle: Ember.computed('model.bundleId', 'availableBundles.[]', function () {
      return this.get('availableBundles').findBy('id', this.get('model.bundleId'));
    }),
    currentBundleDeductible: Ember.computed('model.insuranceBundle', function () {
      let deductible = this.get('model.insuranceBundle.deductible') || 0;
      return deductible * 0.01;
    }),
    ownerOveragePayout: Ember.computed('model.items', function () {
      let chargeItemsSum = null;
      const bookingItems = this.get('model.lineItems');

      if (bookingItems) {
        chargeItemsSum = bookingItems.filter(item => item.mileage || item.generator).reduce((acc, curr) => acc + curr.total, 0);
      }

      return chargeItemsSum;
    }),
    postTripCharges: Ember.computed('model.sdPostTripChargesData', function () {
      // for SD penalties/unexpected charges
      // as addons and miscellaneous charge are already displayed
      if (!this.get('model.sdPostTripChargesData') || !this.get('model.sdPostTripChargesData.security_deposit_post_trip_charges')) {
        return [];
      }

      const sdCharges = this.get('model.sdPostTripChargesData.security_deposit_post_trip_charges');
      const tempCharges = [];
      const unexpectedChargesNames = {
        damage_and_loss: this.intl.t('modal-collect-fees-damage-label'),
        municipal_costs: this.intl.t('modal-collect-fees-municipal-label')
      };

      if (sdCharges.unexpected_post_trip_charges) {
        const unexpectedCharges = sdCharges.unexpected_post_trip_charges;

        for (const charge of unexpectedCharges) {
          if (unexpectedChargesNames[charge.charge_type] && charge.amount) {
            tempCharges.push({
              name: unexpectedChargesNames[charge.charge_type],
              amount: charge.amount / 100,
              description: charge.description
            });
          }
        }
      }

      if (sdCharges.penalties) {
        const penaltiesNames = {
          pet_penalty: this.intl.t('modal-collect-fees-pet-label'),
          festival_penalty: this.intl.t('modal-collect-fees-festival-label'),
          tailgate_penalty: this.intl.t('modal-collect-fees-tailgate-label'),
          smoking_penalty: this.intl.t('modal-collect-fees-smoking-label')
        };

        for (const p of sdCharges.penalties) {
          if (penaltiesNames[p.penalty_type] && p.amount) {
            tempCharges.push({
              name: penaltiesNames[p.penalty_type],
              amount: p.amount / 100
            });
          }
        }
      }

      return tempCharges;
    }),
    retryServiceRequest: (0, _emberConcurrency.task)(function* (req) {
      if (!confirm('Are you sure you want to retry this request?')) {
        return;
      }

      try {
        yield this.adminRequest.post(`integrations/request_log/${req.id}/retry`);
        yield this.model.reload();
      } catch (e) {
        console.log(e);
        this.flashMessages.danger('Something went wrong');
      }
    }),
    getPriceLog: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'showPriceLog', true);
      const response = yield this.adminRequest.request(`admin/bookings/${this.model.id}/price-log`);
      Ember.set(this, 'pricingEntries', response);
    }),
    actions: {
      showGuestTotals(item) {
        let currentItem = this.get('showGuestTotals');

        if (currentItem && item === currentItem) {
          item = null;
        }

        this.set('showGuestTotals', item);
      },

      showTaxRateItem(item) {
        let currentItem = this.get('showItemTaxRates');

        if (currentItem && item === currentItem) {
          item = null;
        }

        this.set('showItemTaxRates', item);
      },

      showMileageTaxRates(item) {
        let currentItem = this.get('showMileageTaxRates');

        if (currentItem && item === currentItem) {
          item = null;
        }

        this.set('showMileageTaxRates', item);
      },

      showGeneratorTaxRates(item) {
        let currentItem = this.get('showGeneratorTaxRates');

        if (currentItem && item === currentItem) {
          item = null;
        }

        this.set('showGeneratorTaxRates', item);
      },

      showOwnerTaxRateItem(item) {
        let currentItem = this.get('showOwnerTaxes');

        if (currentItem && item === currentItem) {
          item = null;
        }

        this.set('showOwnerTaxes', item);
      },

      showPremiumService(item) {
        let currentItem = this.get('showServicePremium');

        if (currentItem && item === currentItem) {
          item = null;
        }

        this.set('showServicePremium', item);
      },

      async showPriceLog() {
        await this.getPriceLog.perform();
      },

      showServiceLog(item) {
        if (this.currentServiceLog && item === this.currentServiceLog) {
          item = null;
        }

        Ember.set(this, 'currentServiceLog', item);
      },

      async updateUser(user, integration) {
        let res = await this.prompt.show('modals/update-user-info', {
          user,
          integration
        }, {
          minWidth: 36
        });

        if (res.status === 'resolved' && this.model) {
          await this.prompt.inform({
            title: 'Success! 😂',
            description: 'Be aware, it can take up to 15 minutes for the service to be applied',
            close: {
              label: 'Close'
            }
          }, {
            minWidth: 26,
            maxWidth: 30
          });
          this.model.reload();
        }
      }

    }
  });

  _exports.default = _default;
});