define("portal/routes/users/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.store.findRecord('user', params.user_id);
    },

    async afterModel(user) {
      let controller = this.controllerFor('users/show');
      await controller.loadTSQueueItems.perform(user);
      await controller.loadUserNotes.perform(user);
      await controller.loadTermsOfServiceVersion.perform(user);
      await controller.loadTesseraCheck.perform(user);
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          numberOfNewNotes: 0,
          termsOfServiceVersion: 1,
          userAcceptedTOSVersion: 1,
          userAcceptedTOSDate: null,
          isTOSAccepted: false,
          isLatestTOSAccepted: false
        });
      }
    }

  });

  _exports.default = _default;
});