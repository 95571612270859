define("@outdoorsyco/ember-shared-data/components/onetrust-banner/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OnetrustBanner = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class OnetrustBanner extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "environment", _descriptor2, this);

      if (!this.environment.isDevelopmentOrTest) {
        // in order to test locally, remove this check
        // note that locally the banner will be shown on every refresh
        this.setupOneTrust();
      }
    }

    async setupOneTrust() {
      const {
        dataDomainScript
      } = this.args;

      if (!dataDomainScript) {
        return;
      } // Create and append the OneTrust SDK script


      const script = document.createElement('script');
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script.type = 'text/javascript';
      script.charset = 'UTF-8';
      script.setAttribute('data-domain-script', dataDomainScript);
      await new Promise((resolve, reject) => {
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      }); // Create and append the wrapper function

      const wrapperScript = document.createElement('script');
      wrapperScript.type = 'text/javascript';
      wrapperScript.text = 'function OptanonWrapper() { }';
      document.head.appendChild(wrapperScript);
      this.observeBanner();
    }

    observeBanner() {
      const observer = new MutationObserver(() => {
        const bannerSdk = document.getElementById('onetrust-banner-sdk');

        if (bannerSdk) {
          this.updateBannerContent();
          observer.disconnect();
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    }

    updateBannerContent() {
      const policyText = document.getElementById('onetrust-policy-text');

      if (policyText) {
        policyText.innerHTML = `
        <div class="onetrust-policy-text-div">
          <p class="onetrust-policy-text-p">
            ${this.intl.t('onetrust-banner-policy-text')}
          </p>
        </div>
      `;
      }

      const acceptButton = document.getElementById('onetrust-accept-btn-handler');

      if (acceptButton) {
        acceptButton.textContent = this.intl.t('onetrust-banner-accept-button-text');
      }

      const rejectButton = document.getElementById('onetrust-reject-all-handler');

      if (rejectButton) {
        rejectButton.textContent = this.intl.t('onetrust-banner-reject-button-text');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OnetrustBanner;
});