define("@outdoorsyco/ember-shared-data/components/survey-show-multiple-choice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fQaAXyIi",
    "block": "{\"symbols\":[\"@question\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"style\"]],\"single-choice\"],null]],null,{\"statements\":[[0,\"  \"],[5,\"select-tag\",[],[[\"@value\",\"@content\",\"@placeholder\"],[[23,1,[\"value\"]],[23,0,[\"choices\"]],[28,\"t\",[\"ui-select\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"multiselect-checkboxes\",[[12,\"class\",\"list-unstyled\"]],[[\"@options\",\"@selection\",\"@updateSelectionValue\"],[[23,0,[\"choices\"]],[23,1,[\"value\"]],true]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@outdoorsyco/ember-shared-data/components/survey-show-multiple-choice/template.hbs"
    }
  });

  _exports.default = _default;
});