define("portal/styles/admin-portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "modal": "modal",
    "in": "in",
    "tabs-inner": "tabs-inner",
    "user-avatar": "user-avatar",
    "main-app": "main-app",
    "booking-approved": "booking-approved",
    "rental-approved": "rental-approved",
    "booking-not-approved": "booking-not-approved",
    "rental-not-approved": "rental-not-approved",
    "rentals": "rentals",
    "fixed-sidebar": "fixed-sidebar",
    "pass-fail": "pass-fail",
    "pass": "pass",
    "fail": "fail",
    "primary-content": "primary-content",
    "panel": "panel",
    "panel-heading": "panel-heading",
    "form-label": "form-label",
    "active": "active",
    "nav-tabs": "nav-tabs",
    "price": "price",
    "table-striped": "table-striped",
    "gateway": "gateway",
    "show-rates": "show-rates",
    "rate-breakdown": "rate-breakdown",
    "avatar": "avatar",
    "avatar-xs": "avatar-xs",
    "avatar-sm": "avatar-sm",
    "avatar-md": "avatar-md",
    "nowrap": "nowrap",
    "w9-completed-section": "w9-completed-section",
    "section-title": "section-title",
    "provisional-approval-info": "provisional-approval-info",
    "tos-completed-section": "tos-completed-section",
    "navbar": "navbar",
    "navbar-search": "navbar-search",
    "form-group": "form-group",
    "input-group": "input-group",
    "input-group-addon": "input-group-addon",
    "search-results": "search-results",
    "result-type": "result-type",
    "results-pagination-box": "results-pagination-box",
    "label": "label",
    "booking": "booking",
    "rental": "rental",
    "campground": "campground",
    "user": "user",
    "transaction": "transaction",
    "meta": "meta",
    "name": "name",
    "campground-container": "campground-container",
    "red-text": "red-text",
    "green-text": "green-text",
    "cursor-pointer": "cursor-pointer",
    "text-underline": "text-underline",
    "state-code-option": "state-code-option",
    "ember-basic-dropdown-content": "ember-basic-dropdown-content",
    "ember-power-select-dropdown": "ember-power-select-dropdown",
    "well": "well",
    "well-danger": "well-danger",
    "text-danger": "text-danger",
    "red-border": "red-border",
    "hint-text": "hint-text",
    "vehicle-location-map": "vehicle-location-map",
    "g-map-canvas": "g-map-canvas",
    "ember-google-map": "ember-google-map",
    "check-run": "check-run",
    "highlight-charge": "highlight-charge",
    "pending-auto-deposit": "pending-auto-deposit",
    "no-style": "no-style",
    "review-actions": "review-actions",
    "action": "action",
    "re-sync-status": "re-sync-status",
    "swap-w9": "swap-w9",
    "vertical-middle": "vertical-middle",
    "toggle-csm": "toggle-csm",
    "status-label": "status-label",
    "error": "error",
    "warning": "warning",
    "ok": "ok",
    "select-tax-outdoorsy-favicon": "select-tax-outdoorsy-favicon",
    "navbar-search-btns": "navbar-search-btns",
    "btns-gap": "btns-gap",
    "flex-container-with-tooltip-icon": "flex-container-with-tooltip-icon",
    "rentals-header": "rentals-header",
    "bold": "bold",
    "total-paid-section": "total-paid-section",
    "owner-credits-description": "owner-credits-description",
    "green": "green",
    "border-bottom-dotted": "border-bottom-dotted",
    "host-fee-taxes": "host-fee-taxes",
    "guest-fee-taxes": "guest-fee-taxes"
  };
  _exports.default = _default;
});