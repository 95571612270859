define("portal/templates/modals/edit-discount-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jfzPT1Km",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-dialog\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"close\"],[3,\"action\",[[23,0,[]],\"closeModal\"]],[8],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-remove\"],[8],[9],[9],[7,\"span\",true],[10,\"class\",\"sr-only\"],[8],[0,\"Close\"],[9],[9],[0,\"\\n      \"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[8],[1,[28,\"if\",[[24,[\"model\",\"isNew\"]],\"Create\",\"Edit\"],null],false],[0,\" Discount Code\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n      \"],[1,[28,\"edit-discount-code\",null,[[\"model\",\"rentalCategories\",\"closeModal\",\"showLocations\"],[[24,[\"model\"]],[24,[\"rentalCategories\"]],\"closeModal\",false]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "portal/templates/modals/edit-discount-code.hbs"
    }
  });

  _exports.default = _default;
});