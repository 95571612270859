define("portal/helpers/show-charge-information-breakdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showChargeInformationBreakdown = showChargeInformationBreakdown;
  _exports.default = void 0;
  const restrictedTypes = ['reservation_charge', 'remainder_charge'];

  function showChargeInformationBreakdown(params
  /*, hash*/
  ) {
    let charge = params[0];

    if (!charge) {
      return false;
    }

    return !restrictedTypes.includes(charge.get('type'));
  }

  var _default = Ember.Helper.helper(showChargeInformationBreakdown);

  _exports.default = _default;
});