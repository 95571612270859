define("@outdoorsyco/ember-shared-data/helpers/printer-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.printerUrlHelper = printerUrlHelper;
  _exports.default = void 0;
  const printerUrl = Ember.Helper.extend({
    session: Ember.inject.service(),

    compute(params, hash) {
      const config = Ember.getOwner(this).resolveRegistration('config:environment') || {};
      const envLocale = ['development', 'test'].indexOf(config.environment) ? 'en' : 'en-us';
      const localeLanguage = this.get('session.user.localeLanguage') || envLocale;
      const templates = {
        insuranceCard: 'insurance-card',
        contract: 'contract',
        binder: 'binder',
        bookings: 'bookings',
        calendars: 'calendars',
        payouts: 'payouts',
        insuranceBinder: 'insurance-binder',
        departureForm: 'departure-form',
        insuranceWaiver: 'insurance-waiver'
      };
      const base = config.APP && config.APP.printerHost ? config.APP.printerHost : 'https://printer.outdoorsy.com';
      const template = params[0];
      const {
        booking,
        rental,
        user,
        payout,
        owner,
        locale
      } = hash;
      let bookingId, bookingToken, vehicleMake, vehicleModel, vehicleYear, vehicleVin, payoutId, userId, apiKey, businessName, lang, country; // filter and construct any additional params added to the hash

      let additionalParams = Object.keys(hash).reduce((h, key) => {
        let whiteList = ['booking', 'rental', 'user', 'payout', 'owner', 'locale'];

        if (!whiteList.includes(key)) {
          return `${h}&${key}=${hash[key]}`;
        }

        return '';
      }, '');

      if (!template || !Object.values(templates).includes(template)) {
        (false && !(false) && Ember.assert('You must pass a valid printer template to the printer-url helper'));
      }

      if (!Ember.isBlank(booking)) {
        bookingId = booking.get('id');
        bookingToken = booking.get('bookingToken');
      }

      if (!Ember.isBlank(rental)) {
        vehicleMake = rental.get('vehicleMake');
        vehicleModel = rental.get('vehicleModel');
        vehicleVin = rental.get('vehicleVin') || '';
        vehicleYear = rental.get('vehicleYear');
        country = rental.get('location.country');
      }

      if (!Ember.isBlank(payout)) {
        payoutId = payout.get('id');
      }

      if (!Ember.isBlank(user)) {
        userId = user.get('id');
        apiKey = user.get('apiKey');
      }

      if (!Ember.isBlank(owner)) {
        businessName = owner.get('businessName');
      } // set printer file language based on (in order of priority) passed locale, passed user's locale, current user locale.


      lang = locale ? locale : !Ember.isBlank(user) ? user.get('localeLanguage') : localeLanguage;
      const routes = {
        [templates.insuranceCard]: `${base}/${template}.pdf?token=${this.get('session.authKey')}&country=${country}&vehicle_make=${vehicleMake}&vehicle_model=${vehicleModel}&vehicle_year=${vehicleYear}&vin=${vehicleVin}&owner=${businessName}${booking ? `&booking=${bookingId}` : null}`,
        [templates.contract]: `${base}/bookings/${bookingId}/${template}.pdf?booking_token=${bookingToken}`,
        [templates.binder]: `${base}/bookings/${bookingId}/${template}.pdf?booking_token=${bookingToken}`,
        [templates.departureForm]: `${base}/bookings/${bookingId}/${template}.pdf?booking_token=${bookingToken}`,
        [templates.bookings]: `${base}/${template}/${bookingId}.pdf?booking_token=${bookingToken}`,
        [templates.calendars]: `${base}/${template}/${userId}/bookings.ics?token=${apiKey}`,
        [templates.payouts]: `${base}/users/${userId}/${template}/${payoutId}.pdf?token=${apiKey}`,
        [templates.insuranceBinder]: `${base}/${template}/${bookingId}.pdf?booking_token=${bookingToken}`,
        [templates.insuranceWaiver]: `${base}/bookings/${bookingId}/${template}.pdf?booking_token=${bookingToken}`
      };
      const noLangTemplates = [templates.calendars];
      let url = routes[template];

      if (!noLangTemplates.includes(template) && lang) {
        url = `${url}&lang=${lang}`;
      }

      url = url + additionalParams;
      return url;
    }

  });

  function printerUrlHelper(context, template, hash) {
    let p = printerUrl.create();
    Ember.setOwner(p, Ember.getOwner(context));
    return p.compute(template, hash);
  }

  var _default = printerUrl;
  _exports.default = _default;
});