define("@outdoorsyco/ember-shared-data/components/booking-file/component", ["exports", "@outdoorsyco/ember-shared-data/components/booking-file/template", "moment"], function (_exports, _template, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'p',
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    file: null,
    // Internal
    signUrl: null,
    expiresAt: 0,
    showModal: false,
    launchFileModalToSign: false,
    loading: false,

    didInsertElement() {
      this._super(...arguments);

      if (this.launchFileModalToSign) {
        this.send('openModal');
      }
    },

    skipVerification: Ember.computed(function () {
      let ENV = Ember.getOwner(this).resolveRegistration('config:environment');
      let config = ENV.HelloSign || {};
      return config.skipVerification;
    }),
    signer: Ember.computed('file.signers', function () {
      let email = this.get('session.currentUser.email');
      let signers = this.get('file.signers') || Ember.A([]);
      return signers.findBy('signer_email_address', email);
    }),
    awaitingSignature: Ember.computed.equal('signer.status_code', 'awaiting_signature'),
    signatureComplete: Ember.computed.equal('signer.status_code', 'signed'),
    validUrl: Ember.computed('signUrl', 'expiresAt', function () {
      return Ember.isPresent(this.get('signUrl')) && this.get('expiresAt') < new Date().getTime();
    }),
    completedText: Ember.computed('signer.signed_at', function () {
      let intl = this.get('intl');
      let signedAt = this.get('signer.signed_at');

      if (!signedAt) {
        return '';
      }

      let t;

      if (typeof signedAt === 'object') {
        t = (0, _moment.default)(signedAt || 0);
      } else {
        t = _moment.default.unix(signedAt || 0);
      }

      return intl.t('comp-booking-file-completed', {
        time: t.format('MMM Do, h:mm a')
      });
    }),
    actions: {
      openModal() {
        this.set('loading', true);

        if (!this.get('validUrl')) {
          let url = `/bookings/${this.get('file.booking.id')}/files/${this.get('file.id')}/sign`;
          this.apiRequest.request(url).then(response => {
            if (response.sign_url) {
              this.set('signUrl', response.sign_url);
              this.set('expiresAt', response.expires_at);
              this.set('showModal', true);
              this.set('loading', false);
            } else {
              this.get('flashMessages').danger(this.get('intl').t('comp-booking-file-already-signed'));
              this.send('signed', {
                event: 'signature_request_signed'
              });
            }
          });
        } else {
          this.set('showModal', true);
          this.set('loading', false);
        }
      },

      /*
        eventData = {
          event: "signature_request_signed",
          signature_id: "7eeacebc2296fe22d5c7b4637b708926"
        }
      */
      signed(eventData) {
        if (eventData && eventData.event === 'signature_request_signed') {
          this.set('signer.status_code', 'signed');
          this.set('signer.signed_at', new Date());

          if (this.get('signedCallback') && typeof this.get('signedCallback') === 'function') {
            this.get('signedCallback')(eventData);
          }
        }
      },

      canceled() {
        /* Sign URL is not valid after closing the session. */
        this.setProperties({
          signUrl: null,
          expiresAt: 0,
          showModal: false,
          loading: false
        });
      }

    }
  });

  _exports.default = _default;
});