define("portal/controllers/bookings/show/charges", ["exports", "@outdoorsyco/ember-shared-data/utils/money", "moment", "ember-concurrency"], function (_exports, _money, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['revenueEvents'],
    adminRequest: Ember.inject.service(),
    apiRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    cardTokens: Ember.inject.service(),
    session: Ember.inject.service(),
    prompt: Ember.inject.service(),
    cardSetup: Ember.inject.service(),
    apiError: Ember.inject.service(),
    refundAmount: null,
    refundSource: null,
    destination: null,
    refundReason: null,
    refundAsCredits: false,
    reverseAmount: null,
    reversalReason: null,
    payoutAmount: null,
    payoutReason: null,
    chargeCard: null,
    adminNote: null,
    pendingRenterChargeDue: (0, _moment.default)(),

    init() {
      this._super(...arguments);

      this.destinations = [{
        value: 'owner',
        text: 'Owner'
      }, {
        value: 'platform',
        text: 'Outdoorsy'
      }];
      this.securityDepositDestinationOptions = ['owner', 'outdoorsy'];
      this.adminNote = '';
      this.damageAndLoss = null;
      this.municipalCosts = null;
      this.penalties = [];
      this.miscellaneousAmount = null;
    },

    refundSources: Ember.computed('model.isCampsite', function () {
      if (this.get('model.isCampsite')) {
        return [{
          value: 'platform',
          text: 'Outdoorsy'
        }, {
          value: 'owner',
          text: 'Campground Payout'
        }];
      }

      return [{
        value: 'platform',
        text: 'Outdoorsy'
      }, {
        value: 'owner',
        text: 'RV Host Payout'
      }];
    }),
    chargeOptions: Ember.computed('renterCharge.pending', 'model.pickedUp', 'session.user.adminLevel', function () {
      const {
        pending
      } = this.renterCharge || {}; // if cost changes after pickup/payout we need to support a 2nd remainder charge

      let opts = ['remainder_charge', 'security_deposit_charge'];

      if (!pending && this.model.isHandedOffOrStarted) {
        opts.push('deductible', 'insurance_claim_charge');
      }

      if (!pending) {
        opts.push('manual');
        opts.push('referral_credit');
      }

      if (pending || this.session.user.adminLevel > 0) {
        opts.push('trip_insurance_charge');
      }

      return opts;
    }),
    securityDepositCharge: Ember.computed('charges', function () {
      return this.get('charges').filterBy('type', 'security_deposit_charge').get('lastObject');
    }),
    showClaimSecurityDeposit: Ember.computed('model.securityDepositStatus', function () {
      return ['authorized', 'paid', 'partially_refunded', 'hold'].indexOf(this.model.get('securityDepositStatus')) !== -1;
    }),
    showHoldSecurityDeposit: Ember.computed('model.securityDepositStatus', function () {
      return ['authorized', 'paid', 'partially_refunded'].indexOf(this.model.get('securityDepositStatus')) !== -1;
    }),
    showReleaseSecurityDeposit: Ember.computed('model.securityDepositStatus', function () {
      return ['authorized', 'paid', 'partially_refunded', 'hold'].indexOf(this.model.get('securityDepositStatus')) !== -1;
    }),
    showDepositActions: Ember.computed('model.{isCampsite,securityDepositStatus}', 'hasSecurityDepositRefund', function () {
      // no security deposit actions for campsites
      if (this.model.isCampsite) {
        return false;
      } // if partially refunded and refund record exists, no other SD actions can happen


      if (this.model.securityDepositStatus === 'partially_refunded' && Ember.get(this, 'hasSecurityDepositRefund')) {
        return false;
      }

      return this.model.securityDepositStatus !== 'refunded';
    }),
    today: Ember.computed(function () {
      return (0, _moment.default)();
    }),
    refundModalTitle: Ember.computed('refundCharge.type', function () {
      return this.get('refundCharge.type') === 'security_deposit_transfer' ? 'Process security deposit refund' : 'Process a refund';
    }),
    affirmChargeLink: Ember.computed('model.id', function () {
      let chargeId = this.get('model.affirmDetails.charge_id');
      let link = '#';

      if (chargeId) {
        link = `https://www.affirm.com/dashboard/#/charges/${chargeId}`;
      }

      return link;
    }),
    hasSecurityDepositRefund: Ember.computed('charges.[]', function () {
      return (this.get('charges') || []).isAny('type', 'security_deposit_refund');
    }),
    hasTransfers: Ember.computed('charges.@each.type', function () {
      return (this.get('charges') || []).isAny('type', 'transfer');
    }),
    isTransferTransactionAccountArchived: Ember.computed('transferTransaction', function () {
      let transactionPpiId = this.transferTransaction.paymentProcessor.id;
      let ownerPpiArray = Ember.get(this, 'model.owner.paymentProcessingInfo');
      return Ember.isEmpty(ownerPpiArray.findBy('id', transactionPpiId));
    }),
    isBookingPaymentProcessorArchived: Ember.computed('bookingPaymentProcessor', function () {
      let ownerPpiArray = Ember.get(this, 'model.owner.paymentProcessingInfo');
      return Ember.isEmpty(ownerPpiArray.findBy('id', Number(this.bookingPaymentProcessor.id)));
    }),
    hasOvercollectedFees: Ember.computed('feeSummary', function () {
      let feeSummary = Ember.get(this, 'model.feeSummary');

      if (feeSummary && feeSummary.refundable) {
        let ccf = feeSummary.refundable;
        let total = ccf.service_fee + ccf.owner_fee + ccf.renter_integrations_fee + ccf.owner_integrations_fee;
        return total > 0;
      }

      return false;
    }),
    getInteractions: (0, _emberConcurrency.task)(function* (charge, event) {
      event.preventDefault();
      const {
        bookingId,
        id: transactionId
      } = charge;
      const url = `/bookings/${bookingId}/transactions/${transactionId}/gateway-interactions`;
      Ember.set(this, 'showInteractions', true);
      const response = yield this.adminRequest.request(url);
      Ember.set(this, 'interactionEntries', response);
    }),
    loadReasons: (0, _emberConcurrency.task)(function* () {
      const reversals = yield this.adminRequest.request('/static/transfers/reversal-reasons');
      Ember.set(this, 'reversalReasons', reversals);
      const refunds = yield this.adminRequest.request('/static/transactions/refund-reasons');
      Ember.set(this, 'refundReasons', refunds);
      const payouts = yield this.adminRequest.request('/static/transactions/payout-reasons');
      Ember.set(this, 'payoutReasons', payouts);
    }),
    loadPayoutIssue: (0, _emberConcurrency.task)(function* () {
      try {
        const result = yield this.adminRequest.request(`payout-issues`, {
          method: 'GET',
          data: {
            booking_id: this.model.id
          }
        });

        if (result.firstObject) {
          Ember.set(this, 'payoutIssue', result.firstObject);
        }
      } catch (e) {
        console.log(e);
      }
    }),
    loadPenaltiesForSecurityDepositForm: (0, _emberConcurrency.task)(function* () {
      var _this$model;

      if ((_this$model = this.model) !== null && _this$model !== void 0 && _this$model.sdPostTripChargesData) {
        const sdPostTripChargesData = this.model.sdPostTripChargesData;
        const rules = sdPostTripChargesData.rental_rules_allowed_on_booking_create;
        const list = [];
        const petPenalty = rules.pet_friendly === false;

        if (petPenalty) {
          list.push({
            label: 'Pet penalty amount',
            amount: null,
            type: 'pet_penalty'
          });
        }

        const festivalPenalty = rules.festival_friendly === false;

        if (festivalPenalty) {
          list.push({
            label: 'Festival penalty amount',
            amount: null,
            type: 'festival_penalty'
          });
        }

        const tailgatePenalty = rules.tailgate_friendly === false;

        if (tailgatePenalty) {
          list.push({
            label: 'Tailgate penalty amount',
            amount: null,
            type: 'tailgate_penalty'
          });
        }

        const smokingPenalty = rules.smoking_allowed === false;

        if (smokingPenalty) {
          list.push({
            label: 'Smoking penalty amount',
            amount: null,
            type: 'smoking_penalty'
          });
        }

        this.set('penalties', list);
      }

      yield;
    }),
    actions: {
      onStripeElementReady(stripeElement) {
        this.set('stripeElement', stripeElement);
      },

      onStripeElementChange(stripeElement, event) {
        this.set('stripeElementComplete', event.complete);
      },

      setDueLater(newValue) {
        // make sure we don't end up with an invalid type for pending charge
        const nonPendingTypeSelected = ['manual', 'referral_credit', 'deductible', 'insurance_claim_charge'].includes(this.renterCharge.type);

        if (newValue === true && nonPendingTypeSelected) {
          this.set('renterCharge.type', 'security_deposit_charge');
        }

        this.set('renterCharge.pending', newValue);
      },

      startCharge() {
        let charge = this.store.createRecord('charge', {
          booking: this.model,
          pending: false
        });
        this.set('renterCharge', charge);

        if (this.get('model.remainderAmount') > 0) {
          this.set('renterCharge.amount', this.get('model.remainderAmount'));
          this.set('renterCharge.type', 'remainder_charge');
        }
      },

      async processCharge() {
        if (!this.get('renterCharge.amount')) {
          this.get('flashMessages').danger('please enter an amount to charge');
          return;
        }

        if (!this.get('renterCharge.type')) {
          this.get('flashMessages').danger('transaction type must be selected');
          return;
        }

        if (this.get('renterCharge.type') === 'manual' && this.model.pickedUp && !this.get('destination')) {
          this.get('flashMessages').danger('Please select a fund receiver.');
          return;
        }

        let useCredits = this.get('renterCharge.type') === 'referral_credit';

        if (!this.get('chargeCard') && !useCredits) {
          this.get('flashMessages').danger('Please select a card or provide a new one');
          return;
        }

        if (!this.isNoteLongEnough(this.get('adminNote'))) {
          return;
        }

        this.set('renterCharge.adminNote', this.get('adminNote'));
        this.set('processingCharge', true);
        let charge = this.get('renterCharge');

        if (this.get('renterCharge.type') === 'manual') {
          charge.set('destination', this.get('destination'));
        } // if pending, ensure date


        if (charge.get('pending')) {
          /* If date doesn't change it's a momment instance, otherwise a string. */
          let dueDate = this.get('pendingRenterChargeDue');

          if (typeof dueDate === 'string') {
            dueDate = (0, _moment.default)(dueDate, 'MM/DD/YYYY');
          }

          charge.set('due', dueDate); // reset back

          this.set('pendingRenterChargeDue', (0, _moment.default)());
        }

        if (this.get('chargeCard') === 'Add a new card') {
          if (!this.stripeElementComplete) {
            this.set('processingCharge', false);
            this.get('flashMessages').danger('please add all card details');
            return;
          }

          this.get('cardTokens').generateTokenFromStripeElement(this.stripeElement).then(async token => {
            if (!useCredits && this.model.hasSetupIntentEnabled) {
              await this.cardSetup.setupCard.perform({
                booking: this.model,
                cardOwner: {
                  id: this.get('model.renter.id')
                },
                card: {
                  key: 'card_token',
                  id: token
                },
                chargeType: this.get('renterCharge.type')
              }).catch(err => {
                this.logger.error(err);
                this.set('processingCharge', false);
                this.get('flashMessages').danger('Could not authorize the card');
              });
            }

            charge.set('stripeToken', token);
            charge.save().then(() => {
              this.set('adminNote', '');
              this.get('flashMessages').success('Charge was successfully submitted');
              this.set('renterCharge', null);
            }).catch(async response => {
              if (this.apiError.isErrorAuthenticationRequired(response)) {
                const card = {
                  key: 'card_token',
                  id: token
                };
                await this._tryToConfirmCard.perform(this.model, {
                  id: this.get('model.renter.id')
                }, charge.get('type'), card, charge.get('amount'), charge, null).catch(err => {
                  this.logger.error(err);
                  this.set('processingCharge', false);
                  this.get('flashMessages').danger('Could not authorize the card');
                });
              } else {
                let error = 'There was an error creating this charge';
                error = this._handleErrorResponse(response, error);
                this.get('flashMessages').danger(error);
              }
            }).finally(() => {
              this.store.query('charge', {
                booking_id: this.model.get('id')
              }).then(charges => {
                this.set('charges', charges);
              });
              this.model.reload();
              this.set('processingCharge', false);
            });
          }).catch(error => {
            this.logger.error(error);
            this.set('processingCharge', false);
            this.get('flashMessages').danger('error creating stripe token');
            return;
          });
          return;
        } //not a new card, use an old card


        charge.set('cardId', this.get('chargeCard.id'));

        if (this.model.hasSetupIntentEnabled) {
          await this.cardSetup.setupCard.perform({
            booking: this.model,
            cardOwner: {
              id: charge.get('booking.renter.id')
            },
            card: {
              key: 'card_id',
              id: this.get('chargeCard.id')
            },
            chargeType: this.get('renterCharge.type')
          }).catch(err => {
            this.logger.error(err);
            this.set('processingCharge', false);
            this.get('flashMessages').danger('Could not authorize the card');
          });
        }

        charge.save().then(() => {
          this.get('flashMessages').success('Charge was successfully submitted');
          this.set('renterCharge', null);
          this.store.query('charge', {
            booking_id: this.model.get('id')
          }).then(charges => {
            this.set('charges', charges);
          });
          this.model.reload();
        }).catch(async response => {
          if (this.apiError.isErrorAuthenticationRequired(response)) {
            const card = {
              key: 'card_id',
              id: this.get('chargeCard.id')
            };
            await this._tryToConfirmCard.perform(this.model, {
              id: charge.get('booking.renter.id')
            }, charge.get('type'), card, charge.get('amount'), charge, null).catch(err => {
              this.logger.error(err);
              this.set('processingCharge', false);
              this.get('flashMessages').danger('Could not authorize the card');
            });
          } else {
            let error = 'There was an error creating this charge';
            error = this._handleErrorResponse(response, error);
            this.get('flashMessages').danger(error);
          }
        }).finally(() => {
          this.set('processingCharge', false);
        });
      },

      processPayout() {
        if (Ember.isBlank(this.get('payoutAmount'))) {
          this.get('flashMessages').danger('Please enter an amount');
          return;
        }

        let reason = this.get('payoutReason');

        if (Ember.isBlank(reason)) {
          this.get('flashMessages').danger('Please enter a reason for this payout');
          return;
        }

        if (!this.isNoteLongEnough(this.get('adminNote'))) {
          return;
        }

        let manualPayoutField = this.get('manualPayoutField');

        if (manualPayoutField === 'insurance_payout' || reason === 'payout-reason-insurance-claim-payout') {
          this.send('sendInsurancePayout');
          return;
        }

        this.set('processingPayout', true);
        let url = `bookings/${this.model.get('id')}/transactions`;
        this.get('adminRequest').post(url, {
          data: JSON.stringify({
            amount: (this.get('payoutAmount') || 0) * 100,
            type: 'application_fee_refund'
            /* This issues a direct transfer/payout with no fees */
            ,
            apply_to_field: manualPayoutField,
            manual_payout: true,
            system_reason: reason,
            admin_note: this.get('adminNote')
          })
        }).then(() => {
          this.get('flashMessages').success('Owner payout was successfully submitted');
          this.set('ownerPayout', false);
          this.set('adminNote', '');
          this.set('charges', this.store.query('charge', {
            booking_id: this.model.get('id')
          }));
        }).catch(response => {
          let error = 'There was an issue creating this payout';
          error = this._handleErrorResponse(response, error);
          this.get('flashMessages').danger(error);
        }).finally(() => {
          this.setProperties({
            processingPayout: false,
            payoutAmount: null,
            payoutReason: null
          });
        });
      },

      sendInsurancePayout() {
        this.get('adminRequest').post(`bookings/${this.model.get('id')}/insurance/payout`, {
          data: {
            amount: (this.get('payoutAmount') || 0) * 100,
            admin_note: this.get('adminNote')
          }
        }).then(() => {
          this.set('adminNote', '');
          this.get('flashMessages').success('Owner payout was successfully submitted');
          this.set('charges', this.store.query('charge', {
            booking_id: this.model.get('id')
          }));
        }).catch(response => {
          let error = 'There was an issue creating this payout';
          error = this._handleErrorResponse(response, error);
          this.get('flashMessages').danger(error);
        }).finally(() => {
          this.set('processingPayout', false);
        });
      },

      startRefund(charge) {
        if (this.session.user.adminLevel < 1) {
          this._notAdminPrompt.perform();

          return;
        }

        this.set('adminNote', ''); // check if backend has a note already?

        if (charge) {
          this.set('refundAmount', 0); // Default to full amount for security deposits.

          if (charge.get('type') === 'security_deposit_transfer' || charge.get('type') === 'security_deposit_charge') {
            this.set('refundAmount', charge.get('remainingAmount'));
          }

          this.set('refundCharge', charge);
        }
      },

      setRefundSource(option) {
        this.set('refundSource', option.value);
      },

      setDestination(option) {
        this.set('destination', option.value);
      },

      setRefundReason(option) {
        this.set('refundReason', option.value);
      },

      setPayoutReason(option) {
        this.set('payoutReason', option.value);
      },

      endRefund() {
        this.store.query('charge', {
          booking_id: this.model.get('id')
        }).then(charges => {
          this.set('charges', charges);
        });
        this.setProperties({
          refundCharge: null,
          refundSource: null,
          refundReason: null,
          refundAsCredits: false,
          error: null,
          fullRefund: false,
          loading: null,
          refundAmount: null
        });
      },

      startReversal(tx) {
        if (tx) {
          this.set('reverseAmount', 0); // Default to full amount

          this.set('reverseAmount', tx.get('remainingAmount'));
          this.set('transferTransaction', tx);
        }

        this.set('adminNote', ''); // check if backend has a note already?
      },

      setReversalReason(option) {
        this.set('reversalReason', option.value);
      },

      endReversal() {
        this.store.query('charge', {
          booking_id: this.model.get('id')
        }).then(charges => {
          this.set('charges', charges);
        });
        this.setProperties({
          transferTransaction: null,
          reversingTransfer: false,
          error: null,
          loading: null,
          reverseAmount: null,
          reversalReason: null
        });
      },

      processRefund() {
        this.set('error', null);
        let amount = Number(this.get('refundAmount'));

        if (!amount || isNaN(amount)) {
          this.get('flashMessages').danger('Please enter a refund amount');
          return;
        }

        if (amount > this.get('refundCharge.remainingAmount')) {
          this.get('flashMessages').danger('Amount must be less than the remaining amount.');
          return;
        }

        if (!this.get('refundReason')) {
          this.get('flashMessages').danger('A refund reason must be selected.');
          return;
        }

        if (this.get('refundCharge.type') === 'security_deposit_transfer') {
          this.set('refundSource', 'owner');
        }

        if (this.get('refundCharge.type') === 'referral_credit') {
          this.set('refundSource', 'platform');
        }

        if (!this.get('refundSource')) {
          this.get('flashMessages').danger('A refund source must be selected.');
          return;
        }

        if (!this.isNoteLongEnough(this.get('adminNote'))) {
          return;
        }

        this.set('loading', true);
        let data = {
          amount: Math.ceil((amount * 100).toFixed(2)),
          refund_source: this.get('refundSource'),
          refund_reason: this.get('refundReason'),
          as_credits: this.get('refundAsCredits'),
          admin_note: this.get('adminNote')
        };
        this.get('adminRequest').request(`bookings/${this.model.get('id')}/transactions/${this.get('refundCharge.id')}/refund`, {
          method: 'POST',
          data: JSON.stringify(data)
        }).then(() => {
          this.set('adminNote', '');
          this.model.reload();
          this.send('endRefund');
        }).catch(response => {
          let error = this._handleErrorResponse(response, 'There was an error processing your refund');

          this.setProperties({
            error: error,
            loading: false
          });
        });
      },

      stripeSync(charge) {
        if (this.session.user.adminLevel < 1) {
          this._notAdminPrompt.perform();

          return;
        }

        if (Ember.isBlank(charge)) {
          this.get('flashMessages').danger("I don't know which charge to sync");
          return;
        }

        this.set('syncingCharge', true);
        return this.get('adminRequest').request(`bookings/${this.get('model.id')}/transactions/${charge.get('id')}/sync`, {
          method: 'POST'
        }).then(() => {
          this.store.query('charge', {
            booking_id: this.get('model.id')
          }).then(charges => {
            this.set('charges', charges);
            this.set('syncingCharge', false);
            this.get('flashMessages').success("We're in sync with stripe");
          });
        }).catch(response => {
          let error = this._handleErrorResponse(response, 'There was an error syncing with stripe');

          this.setProperties({
            error: error,
            syncingCharge: false
          });
        });
      },

      stripeTransfer(charge) {
        if (Ember.isBlank(charge)) {
          this.get('flashMessages').danger("I don't know which charge to transfer");
          return;
        }

        if (!confirm('Are you sure you want to issue a transfer for this charge?')) {
          return false;
        }

        this.set('transferringCharge', true);
        return this.get('adminRequest').request(`bookings/${this.get('model.id')}/transactions/${charge.get('id')}/transfer`, {
          method: 'POST'
        }).then(() => {
          this.store.query('charge', {
            booking_id: this.get('model.id')
          }).then(charges => {
            this.set('charges', charges);
            this.set('transferringCharge', false);
            this.get('flashMessages').success('Success');
          });
        }).catch(response => {
          let error = this._handleErrorResponse(response, 'There was an error transferring with stripe');

          this.setProperties({
            error: error,
            transferringCharge: false
          });
        });
      },

      processReversal() {
        let transfer = this.transferTransaction;

        if (Ember.isBlank(transfer)) {
          this.flashMessages.danger('Select which transfer you would like to reverse.');
          return;
        }

        let amount = Number(this.get('reverseAmount'));

        if (!amount || isNaN(amount) || amount === 0) {
          this.get('flashMessages').danger('Please enter a reversal amount');
          return;
        }

        if (amount > transfer.get('amount')) {
          this.get('flashMessages').danger('Reverse amount cannot be greater than transfer amount');
          return;
        }

        if (!this.get('reversalReason')) {
          this.get('flashMessages').danger('A reversal reason must be selected.');
          return;
        }

        if (!this.isNoteLongEnough(this.get('adminNote'))) {
          return;
        }

        if (!confirm(`Are you sure you want to reverse ${amount} of this transfer?`)) {
          return false;
        }

        this.set('reversingTransfer', true);
        return this.adminRequest.request(`bookings/${this.model.id}/transactions/${transfer.id}/reverse`, {
          method: 'POST',
          data: JSON.stringify({
            amount: Math.ceil(amount * 100),
            reversal_reason: this.get('reversalReason'),
            admin_note: this.get('adminNote')
          })
        }).then(() => {
          this.set('adminNote', '');
          this.model.reload();
          this.send('endReversal');
          this.flashMessages.success('Success');
        }).catch(response => {
          let error = this._handleErrorResponse(response, 'There was an error reversing this transfer');

          this.setProperties({
            error: error,
            reversingTransfer: false
          });
        });
      },

      startEdit(charge) {
        // edge case where pending transaction doesn't have a due date
        if (charge.attempts > 0 && charge.due === null) {
          charge.set('due', (0, _moment.default)());
        }

        this.set('editCharge', charge);
      },

      beforeEditClose() {
        this.set('editCharge', null);
      },

      async updateCharge(charge) {
        if (!this.get('chargeCard')) {
          this.get('flashMessages').danger('Please select a card');
          return;
        }

        let newCardId = this.get('chargeCard.id') || 0;

        if (this.get('chargeCard') === 'Add a new card') {
          if (!this.stripeElementComplete) {
            this.get('flashMessages').danger('please add all card details');
            return;
          }

          try {
            const token = await this.cardTokens.generateTokenFromStripeElement(this.stripeElement);
            let card = this.get('store').createRecord('card', {
              owner: this.get('model.renter'),
              stripeToken: token,
              paymentProcessor: {
                country: this.bookingPaymentProcessor.get('country') || 'US'
              }
            });
            await card.save();
            newCardId = card.get('id');
          } catch (error) {
            this.logger.error(error);
            this.get('flashMessages').danger('error creating stripe token');
            return;
          }
        }
        /* If date doesn't change it's a momment instance, otherwise a string. */


        let dueDate = charge.due;

        if (typeof dueDate === 'string') {
          dueDate = (0, _moment.default)(dueDate, 'MM/DD/YYYY'); // .format("YYYY-MM-DD");

          this.set('editCharge.due', dueDate);
        }

        let data = {
          due: dueDate.format('YYYY-MM-DD'),
          card_id: newCardId
        };
        let url = `bookings/${this.model.get('id')}/transactions/${charge.get('id')}`;
        return this.get('adminRequest').request(url, {
          method: 'PATCH',
          data: JSON.stringify(data)
        }).then(() => {
          this.setProperties({
            editCharge: null,
            error: null,
            loading: null
          });
          this.set('charges', this.store.query('charge', {
            booking_id: this.model.get('id')
          }));
          this.get('flashMessages').success('Charge successfully updated');
        }).catch(response => {
          let error = this._handleErrorResponse(response, 'There was an error processing your request');

          this.setProperties({
            error: error,
            loading: false
          });
          this.get('flashMessages').danger(error);
        });
      },

      startRetryPayout(charge) {
        this.set('failedPayout', charge);
      },

      retryPayout() {
        let url = `bookings/${this.model.get('id')}/transactions/${this.get('failedPayout.id')}/retry`;
        return this.get('adminRequest').request(url, {
          method: 'POST'
        }).then(() => {
          /* Mark charge as not-failed */
          this.set('failedPayout.failed', false);
          this.setProperties({
            failedPayout: null,
            error: null,
            loading: null
          });
          this.get('flashMessages').success('Payout has been retried');
        }).catch(response => {
          let error = 'There was an error processing your request';
          error = this._handleErrorResponse(response, error);
          this.setProperties({
            error: error,
            loading: false
          });
          this.get('flashMessages').danger(error);
        });
      },

      deletePendingTx(charge) {
        if (!confirm('Are you sure you want to delete this pending transaction?')) {
          return false;
        }

        if (charge.get('due')) {
          charge.destroyRecord().then(() => {
            this.setProperties({
              error: null,
              loading: null
            });
            this.get('flashMessages').success('Pending Transaction Deleted');
          }).catch(response => {
            let error = 'There was an error processing your request';
            error = this._handleErrorResponse(response, error);
            this.setProperties({
              error: error,
              loading: false
            });
            this.get('flashMessages').danger(error);
          });
        }
      },

      showRelated(charge) {
        this.get('charges').forEach(c => {
          c.set('highlight', false);
          let chargeTransferId = charge.get('transfer.id');

          if (chargeTransferId === parseInt(c.get('id'))) {
            c.set('highlight', true);
          } // payouts also have the same transfer id


          if (chargeTransferId === parseInt(c.get('transfer.id'))) {
            c.set('highlight', true);
          }

          if (c.get('originalChargeId') === parseInt(charge.get('id'))) {
            c.set('highlight', true);
          }
        });
        charge.set('highlight', true);
      },

      clearHighlights() {
        this.get('charges').forEach(c => {
          c.set('highlight', false);
        });
      }

    },

    isNoteLongEnough(note) {
      if (!note) {
        this.get('flashMessages').danger('Please leave a note for this payout');
        return false;
      } else if (note.length < 20) {
        this.get('flashMessages').danger('Your note needs to be at least 20 characters long');
        return false;
      }

      return true;
    },

    _notAdminPrompt: (0, _emberConcurrency.task)(function* () {
      yield this.prompt.inform({
        title: `Sorry! You aren't authorized for this.`,
        description: 'grab a team lead for help! :)',
        close: {
          label: 'Close'
        }
      }, {
        minWidth: 26,
        maxWidth: 30
      });
    }),
    _releaseSecurityDeposit: (0, _emberConcurrency.task)(function* () {
      if (!confirm('Are you sure you want to release the security deposit')) {
        return;
      }

      yield this.get('adminRequest').request(`bookings/${this.get('model.id')}/deposit/release`, {
        method: 'POST',
        data: JSON.stringify({})
      }).then(() => {
        this.model.reload(); // reload charges

        this.store.query('charge', {
          booking_id: this.model.get('id')
        }).then(charges => {
          this.set('charges', charges);
        });
        this.flashMessages.success('Successfully released security deposit');
      }).catch(response => {
        let error = 'There was an issue releasing the deposit';
        error = this._handleErrorResponse(response, error);
        this.flashMessages.danger(error);
      });
    }),
    _processClaimSecurityDeposit: (0, _emberConcurrency.task)(function* () {
      if (!this.get('claimSecurityDepositDestination')) {
        this.get('flashMessages').danger('Add a destination');
        return;
      }

      const destination = this.get('claimSecurityDepositDestination');
      const isForOutdoorsy = destination === 'outdoorsy';
      const isForOwner = destination === 'owner';

      if (isForOutdoorsy && !this.get('claimSecurityDepositAmount')) {
        this.get('flashMessages').danger('Add an amount');
        return;
      }

      if (!this.isNoteLongEnough(this.get('adminNote'))) {
        return;
      }

      if (isForOwner && !this.damageAndLoss && !this.municipalCosts && !this.miscellaneousAmount && !this.penalties.some(p => p.amount)) {
        this.get('flashMessages').danger('Add an amount to at least one field');
        return;
      }

      const data = {
        destination: destination,
        admin_note: this.adminNote
      };

      if (isForOutdoorsy) {
        data.amount = Math.round((this.claimSecurityDepositAmount || 0) * 100);
      }

      if (isForOwner) {
        const adminDescription = 'Admin charge';
        const securityDepositCharges = {
          unexpected_post_trip_charges: []
        };

        if (this.damageAndLoss) {
          securityDepositCharges.unexpected_post_trip_charges.push({
            charge_type: 'damage_and_loss',
            amount: Math.round(this.damageAndLoss * 100),
            description: adminDescription
          });
        }

        if (this.municipalCosts) {
          securityDepositCharges.unexpected_post_trip_charges.push({
            charge_type: 'municipal_costs',
            amount: Math.round(this.municipalCosts * 100),
            description: adminDescription
          });
        }

        if (this.miscellaneousAmount) {
          securityDepositCharges.miscellaneous_charges = {
            amount: Math.round(this.miscellaneousAmount * 100),
            description: adminDescription
          };
        } // penalties


        const selectedPenalties = this.penalties.filter(p => p.amount).map(p => {
          return {
            penalty_type: p.type,
            amount: Math.round(p.amount * 100)
          };
        });

        if (selectedPenalties.length) {
          securityDepositCharges.penalties = selectedPenalties;
        }

        data.security_deposit_post_trip_charges = securityDepositCharges;
      }

      try {
        yield this.get('adminRequest').request(`bookings/${this.get('model.id')}/deposit/claim`, {
          method: 'POST',
          data: data
        }).then(() => {
          this.model.reload(); // reload charges

          this.store.query('charge', {
            booking_id: this.model.get('id')
          }).then(charges => {
            this.set('charges', charges);
          }); // close modal

          this.set('claimSecurityDeposit', false);
          this.set('adminNote', '');
          this.flashMessages.success('Success');
        }).catch(response => {
          let error = 'There was an issue claiming the deposit';
          error = this._handleErrorResponse(response, error);
          this.flashMessages.danger(error);
        });
      } catch (e) {
        let error = Ember.get(e, 'payload.error');
        this.get('flashMessages').danger(error);
        return;
      }

      this.get('flashMessages').success('Claim request submitted');
      this.setProperties({
        claimSecurityDepositAmount: null,
        claimSecurityDepositDestination: null
      });
    }),
    _holdSecurityDeposit: (0, _emberConcurrency.task)(function* () {
      if (!confirm('Are you sure you want to hold the security deposit')) {
        return;
      }

      yield this.get('adminRequest').request(`bookings/${this.get('model.id')}/deposit/hold`, {
        method: 'POST',
        data: JSON.stringify({})
      }).then(() => {
        this.model.reload();
        this.flashMessages.success('Security deposit has been put on hold');
      }).catch(response => {
        let error = 'There was an issue holding the deposit';
        error = this._handleErrorResponse(response, error);
        this.flashMessages.danger(error);
      });
    }),
    _refundOvercollectedFees: (0, _emberConcurrency.task)(function* () {
      if (!confirm('This will refund fees we overcollected')) {
        return;
      }

      try {
        yield this.get('adminRequest').request(`admin/bookings/${this.get('model.id')}/refund-overcollected-fees`, {
          method: 'POST',
          data: JSON.stringify({})
        });
        this.model.reload();
        this.flashMessages.success('Refund sent to owner');
      } catch (response) {
        let error = 'There was an issue refunding fees';
        error = this._handleErrorResponse(response, error);
        this.flashMessages.danger(error);
      }
    }),
    _bookingStripeSync: (0, _emberConcurrency.task)(function* () {
      yield this.get('adminRequest').request(`bookings/${this.get('model.id')}/transactions/sync`, {
        method: 'POST'
      }).then(() => {
        this.store.query('charge', {
          booking_id: this.get('model.id')
        }).then(charges => {
          this.set('charges', charges);
          this.get('flashMessages').success("We're in sync with stripe");
        });
      }).catch(response => {
        let error = this._handleErrorResponse(response, 'There was an error syncing with stripe');

        this.setProperties({
          error: error,
          syncingCharge: false
        });
      });
    }),
    _chargePendingTx: (0, _emberConcurrency.task)(function* (charge) {
      if (!confirm('Are you sure you want to charge this pending transaction immediately?')) {
        return false;
      }

      if (charge.get('due')) {
        let url = `bookings/${this.get('model.id')}/transactions/${charge.get('id')}/charge`;
        yield this.get('adminRequest').post(url).then(() => {
          this.setProperties({
            error: null,
            loading: null
          });
          this.get('flashMessages').success('Pending Transaction Charged');
          this.model.reload();
        }).catch(async response => {
          if (this.apiError.isErrorAuthenticationRequired(response)) {
            const card = {
              key: 'card_id',
              id: charge.get('cardId')
            };
            await this._tryToConfirmCard.perform({
              id: this.get('model.id')
            }, {
              id: this.get('model.renter.id')
            }, charge.get('type'), card, charge.get('amount'), null, url);
            return;
          }

          let error = 'There was an error charging the pending transaction';
          error = this._handleErrorResponse(response, error);
          this.setProperties({
            error: error,
            loading: false
          });
          this.get('flashMessages').danger(error);
        }).finally(() => {
          // reload charges
          this.store.query('charge', {
            booking_id: this.model.get('id')
          }).then(charges => {
            this.set('charges', charges);
          });
        });
      }
    }),
    _tryToConfirmCard: (0, _emberConcurrency.task)(function* (booking, cardOwner, chargeType, card, amount, charge, url) {
      const response = yield this.cardSetup.confirmCard.perform({
        authorize: true,
        booking: booking,
        cardOwner: cardOwner,
        amount: (0, _money.dollarsToCents)(amount),
        card: card,
        chargeType: chargeType
      });

      if (url !== null) {
        let data = {
          payment_intent_id: response.id
        };
        yield this.get('adminRequest').request(url, {
          method: 'POST',
          data: JSON.stringify(data)
        }).then(() => {
          this.setProperties({
            error: null,
            loading: null
          });
          this.get('flashMessages').success('Pending Transaction Charged');
          this.model.reload();
        }).catch(response => {
          let error = 'Authorization failed';
          error = this._handleErrorResponse(response, error);
          this.setProperties({
            error: error,
            loading: false
          });
          this.get('flashMessages').danger(error);
        }).finally(() => {
          this.store.query('charge', {
            booking_id: this.model.get('id')
          }).then(charges => {
            this.set('charges', charges);
          });
        });
      } else if (charge !== null) {
        charge.set('paymentIntentId', response.id);
        charge.save().then(() => {
          this.get('flashMessages').success('Charge was successfully submitted');
          this.set('renterCharge', null);
        }).catch(response => {
          let error = 'There was an error creating this charge';
          error = this._handleErrorResponse(response, error);
          this.get('flashMessages').danger(error);
        });
      }
    }),
    _emailChargeReceipt: (0, _emberConcurrency.task)(function* (charge) {
      if (Ember.isBlank(charge)) {
        this.flashMessages.danger("I don't know which charge to use");
        return;
      }

      let renterEmail = Ember.get(this, 'model.renter.email');
      let bookingId = Ember.get(this, 'model.id');

      try {
        yield this.adminRequest.post(`bookings/${bookingId}/transactions/${charge.get('id')}/receipt`);
        this.flashMessages.success(`Receipt sent to ${renterEmail}`);
      } catch (response) {
        let error = this._handleErrorResponse(response, 'There was an error emailing the receipt');

        this.setProperties({
          error: error
        });
      }
    }),
    _deleteScheduledPayout: (0, _emberConcurrency.task)(function* (charge) {
      if (this.session.user.adminLevel < 3) {
        this._notAdminPrompt.perform();

        return;
      }

      if (Ember.isBlank(charge)) {
        this.flashMessages.danger("I don't know which charge to use");
        return;
      }

      if (!confirm('Are you sure you want to delete this scheduled payout?')) {
        return false;
      }

      let bookingId = Ember.get(this, 'model.id');

      try {
        yield this.adminRequest.delete(`bookings/${bookingId}/payout/${charge.get('id')}`);
        this.flashMessages.success(`Scheduled Payout deleted`);
      } catch (response) {
        this.flashMessages.danger('There was an error while deleting scheduled payout');
      }
    }),
    redeliverPaymentEvent: (0, _emberConcurrency.task)(function* (charge) {
      if (Ember.isBlank(charge)) {
        this.flashMessages.danger("I don't know which charge to use");
        return;
      }

      let bookingId = Ember.get(this, 'model.id');

      try {
        yield this.adminRequest.post(`bookings/${bookingId}/transactions/${charge.get('id')}/payment-event`);
        this.flashMessages.success(`Event created. It may take a minute for it to show up`);
      } catch (response) {
        let error = this._handleErrorResponse(response, 'There was an error creating the event');

        this.setProperties({
          error: error
        });
      }
    }),

    _handleErrorResponse(response, defaultMsg) {
      let msg = defaultMsg;

      if (Ember.isPresent(response) && Ember.isPresent(response.errors) && response.errors.length) {
        let title = response.errors[0].title;
        let body = Ember.isPresent(response.errors[0].detail) ? response.errors[0].detail.error : '';
        msg = `${title} - ${body}`;
      } else if (response.payload && response.payload.error) {
        msg = response.payload.error;

        if (response.payload.original_error) {
          let obj = JSON.parse(response.payload.original_error);
          msg = obj.message;
        }
      }

      return msg;
    }

  });

  _exports.default = _default;
});