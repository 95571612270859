define("portal/controllers/users/show", ["exports", "ember-concurrency", "dayjs"], function (_exports, _emberConcurrency, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['showSecretButtons'],
    adminRequest: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    numberOfNewNotes: 0,
    termsOfServiceVersion: 1,
    userAcceptedTOSVersion: 1,
    userAcceptedTOSDate: null,
    isTOSAccepted: false,
    isLatestTOSAccepted: false,
    loadUserNotes: (0, _emberConcurrency.task)(function* (user) {
      try {
        let userId = Ember.get(user, 'id');
        let fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 1);
        let url = `admin/notes?internal=true&user_id=${userId}&from=${fromDate.toISOString()}`;
        let data = yield this.adminRequest.request(url);
        this.set('numberOfNewNotes', data.length);
      } catch (e) {
        this.set('numberOfNewNotes', 0);
      }
    }),
    loadTermsOfServiceVersion: (0, _emberConcurrency.task)(function* (user) {
      try {
        let url = 'tos/version';
        let data = yield this.adminRequest.request(url);

        if (data && data.version) {
          this.set('termsOfServiceVersion', parseInt(data.version));
        }

        if (user && user.metadata && user.metadata.accepted_tos_version) {
          if (user.metadata.accepted_tos_version) {
            this.set('userAcceptedTOSVersion', parseInt(user.metadata.accepted_tos_version));
            this.set('isTOSAccepted', true);
          }

          if (user.metadata.tos_acceptance_date) {
            this.set('userAcceptedTOSDate', user.metadata.tos_acceptance_date);
          }
        }

        if (this.termsOfServiceVersion <= this.userAcceptedTOSVersion) {
          this.set('isLatestTOSAccepted', true);
        }
      } catch (e) {
        this.set('termsOfServiceVersion', 1);
        this.set('userAcceptedTOSVersion', 1);
        this.set('isLatestTOSAccepted', false);
      }
    }),
    loadTSQueueItems: (0, _emberConcurrency.task)(function* (user) {
      let items = yield this.store.query('ts-queue-item', {
        status: 'unchecked,in_review,rejected',
        user_id: Ember.get(user, 'id')
      });
      Ember.set(this, 'tsQueueItems', items);
    }),
    forceResetPassword: (0, _emberConcurrency.task)(function* (value) {
      let userId = Number(this.get('model.id'));
      let url = `admin/users/${userId}/force-password-reset`;
      yield this.adminRequest.post(url, {
        method: 'POST',
        data: JSON.stringify({
          value: value
        })
      });
      yield this.model.reload();
    }),
    disableSocialAuth: (0, _emberConcurrency.task)(function* (value) {
      let userId = Number(this.get('model.id'));
      let url = `admin/users/${userId}/disable-social-login`;
      yield this.adminRequest.post(url, {
        method: 'POST',
        data: JSON.stringify({
          value: value
        })
      });
      yield this.model.reload();
    }),
    loadTesseraCheck: (0, _emberConcurrency.task)(function* (user) {
      const args = {
        user_id: Ember.get(user, 'id')
      };
      const options = {
        limit: 1,
        order_by: '-created',
        validator: 'tessera',
        contains_args: JSON.stringify(args),
        status: 'passed'
      };

      try {
        const response = yield this.get('adminRequest').request(`admin/validations`, {
          data: options
        });

        if (response && response.length) {
          this.set('tesseraCheckPassed', true);
          this.set('tesseraValidatedOn', response[0].created);
        }
      } catch (err) {
        this.flashMessages.danger('There was an issue loading user validations...');
      }
    }),
    formattedTOSDate: Ember.computed('userAcceptedTOSDate', function () {
      if (!this.userAcceptedTOSDate) {
        return null;
      }

      return (0, _dayjs.default)(this.userAcceptedTOSDate).format('MMM D, YYYY h:mm A');
    }),
    actions: {
      closeTSModal() {
        this.set('reportToTrustAndSafety', null);
      },

      successTSReport() {
        this.loadTSQueueItems.perform(this.model);
      }

    }
  });

  _exports.default = _default;
});